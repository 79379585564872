import React from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Section from '../components/section';

export default function FeedTemplate({ data }) {
  const pageInfo = {
    id: 'feed-page',
    url: `/news/${data.strapiFeed.slug}`,
    title: data.strapiFeed.shortTitle,
    description: data.strapiFeed.title,
    keywords: data.strapiFeed.keywords,
    image: data.strapiFeed.cover.publicURL,
    breadcrumbs: [
      {
        title: 'Главная',
        link: '/',
      },
      {
        title: 'Новости',
        link: '/news',
      },
      {
        title: data.strapiFeed.shortTitle,
        link: null,
      },
    ],
  };

  return (
    <Layout pageInfo={pageInfo}>
      <Section>
        <article>
          <div className="feed-cover">
            <GatsbyImage
              image={data.strapiFeed.cover.childImageSharp.gatsbyImageData}
              alt={data.strapiFeed.title}
            />
          </div>
          <ReactMarkdown>
            {data.strapiFeed.article}
          </ReactMarkdown>
          {
            data.strapiFeed.video
              ? (
                <React.Fragment>
                  <h2>
                    Посмотрите наше видео
                  </h2>
                  <div className="iframe-container">
                    <iframe
                      title={data.strapiFeed.title}
                      src={data.strapiFeed.video}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen=""
                    />
                  </div>
                </React.Fragment>
              )
              : null
          }
        </article>
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query($strapiId: Int!) {
    strapiFeed(strapiId: { eq: $strapiId }) {
      strapiId
      slug
      shortTitle
      title
      keywords
      date(formatString: "DD.MM.YYYY")
      cover {
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
      article
      video
    }
  }
`;
